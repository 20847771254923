import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { withRouter, Redirect } from 'react-router-dom'

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`

const handleLogin = async(provider, history, loggedIn, setLoggedIn) => {
  if (loggedIn) {
    return
  }
  let oauthToken = ''
  switch (provider) {
  case 'gitlab': {
    const params = new URLSearchParams(history.location.hash.slice(1))
    localStorage.setItem('gitlab_token', params.get('access_token'))
    localStorage.setItem('gitlab_token_type', params.get('token_type'))
    oauthToken = params.get('access_token')
    break
  }
  case 'github':
  default: {
    const params = new URLSearchParams(history.location.search)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/exchange-token?code=${params.get(
        'code'
      )}&provider=${provider}`,
      { method: 'POST' }
    )
    if (!response.ok) {
      message.error(await response.text())
      return
    }
    const providerToken = await response.json()
    localStorage.setItem('github_token', providerToken.access_token)
    localStorage.setItem('github_scope', providerToken.scope)
    oauthToken = providerToken.access_token
    break
  }
  }
  const resp = await fetch(LOGIN_URL, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      type: `${provider}Token`,
      [`${provider}Token`]: oauthToken
    })
  })
  if (!resp.ok) {
    message.error(await resp.text())
    return
  }
  const tokenResponse = await resp.json()
  localStorage.setItem('token', tokenResponse.token)
  setLoggedIn({ loggedIn: true })
}

export const AuthContainer = withRouter(
  ({
    history,
    match: {
      params: { provider }
    }
  }) => {
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
      handleLogin(provider, history, loggedIn, setLoggedIn)
    })
    if (loggedIn) {
      return <Redirect to="/" />
    }
    return <div />
  }
)
