import React from 'react'
import { Card, Popconfirm, Icon, Tooltip } from 'antd'
import styled, { css } from 'styled-components'

const StyledProxeeCard = styled(Card)`
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  ${props =>
    props.plus &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  :hover {
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  }
  transition: all 0.3s;
`

export const ProxeeCard = ({
  proxee,
  setSelectedProxee,
  setOpenDrawer,
  setDrawerType,
  updateProxee,
  history
}) => (
  <StyledProxeeCard
    key={proxee.id}
    title={proxee.name}
    onClick={() => {
      history.push(`/proxees/${proxee.id}/requests`)
    }}
    actions={[
      <Tooltip title="Edit">
        <Icon
          type="edit"
          onClick={e => {
            setSelectedProxee(proxee)
            setOpenDrawer(true)
            setDrawerType('edit')
            e.stopPropagation()
          }}
        />
      </Tooltip>,
      <Tooltip title="Proxee URL">
        <Icon
          type="link"
          onClick={e => {
            window.open(`https://${proxee.subDomain}.px.thangntt.com`, '_blank')
            e.stopPropagation()
          }}
        />
      </Tooltip>,
      <Tooltip title={proxee.activated ? 'Pause' : 'Start'}>
        <Popconfirm
          placement="topLeft"
          title={`${proxee.activated ? 'Pause' : 'Start'} this proxee?`}
          onClick={e => e.stopPropagation()}
          onConfirm={e => {
            e.stopPropagation()
            updateProxee({ ...proxee, activated: !proxee.activated })
          }}
          okText="Yes"
          cancelText="No"
        >
          <Icon
            type={proxee.activated ? 'play-circle' : 'stop'}
            theme="twoTone"
            twoToneColor={proxee.activated ? '#52c41a' : '#ff0000'}
          />
        </Popconfirm>
      </Tooltip>
    ]}
  >
    <Card.Meta
      title={
        <span>
          Sub domain:{' '}
          <a href={`https://${proxee.subDomain}.px.thangntt.com`}>
            {proxee.subDomain}
          </a>
        </span>
      }
      description={
        <span>
          Target URL:
          <br />
          <a target="_blank" rel="noopener noreferrer" href={proxee.targetUrl}>
            {proxee.targetUrl}
          </a>
        </span>
      }
    />
  </StyledProxeeCard>
)
