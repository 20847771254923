import React from 'react'
import gql from 'graphql-tag'
import { Loading } from '../common/Loading'
import { Query, Mutation } from 'react-apollo'
import { ProxeeListComponent } from './ProxeeListComponent'

const LIST_PROXEE = gql`
  query ListProxee {
    proxees {
      id
      name
      subDomain
      targetUrl
      activated
    }
  }
`

const CREATE_PROXEE = gql`
  mutation CreateProxee(
    $name: String!
    $targetUrl: String!
    $subDomain: String!
    $activated: Boolean
  ) {
    createProxee(
      input: {
        name: $name
        targetUrl: $targetUrl
        subDomain: $subDomain
        activated: $activated
      }
    ) {
      id
      name
      subDomain
      targetUrl
      activated
    }
  }
`

const UPDATE_PROXEE = gql`
  mutation UpdateProxee(
    $id: Int!
    $name: String
    $targetUrl: String
    $activated: Boolean
  ) {
    updateProxee(
      input: {
        id: $id
        name: $name
        targetUrl: $targetUrl
        activated: $activated
      }
    ) {
      id
      name
      subDomain
      targetUrl
      activated
    }
  }
`

export const ProxeeListContainer = () => {
  return (
    <Mutation mutation={CREATE_PROXEE}>
      {createProxeeMutation => (
        <Mutation mutation={UPDATE_PROXEE}>
          {updateProxeeMutation => (
            <Query query={LIST_PROXEE}>
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loading />
                }
                if (error) {
                  return error.message
                }
                const { proxees } = data
                const createProxee = proxee =>
                  createProxeeMutation({
                    variables: proxee,
                    optimisticResponse: {
                      __typename: 'Mutation',
                      createProxee: {
                        __typename: 'Proxee',
                        ...proxee
                      }
                    },
                    update: (proxy, { data: { createProxee } }) => {
                      const _data = proxy.readQuery({
                        query: LIST_PROXEE
                      })
                      const { proxees } = _data
                      proxees.push(createProxee)
                      proxy.writeQuery({
                        query: LIST_PROXEE,
                        data: _data
                      })
                    }
                  })
                const updateProxee = proxee =>
                  updateProxeeMutation({
                    variables: proxee,
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProxee: {
                        __typename: 'Proxee',
                        ...proxee
                      }
                    }
                  })
                return (
                  <ProxeeListComponent
                    proxees={proxees}
                    createProxee={createProxee}
                    updateProxee={updateProxee}
                  />
                )
              }}
            </Query>
          )}
        </Mutation>
      )}
    </Mutation>
  )
}
