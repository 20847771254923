import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { List, PageHeader, Layout, message } from 'antd'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import SimpleBar from 'simplebar-react'
import { RequestLogDetail } from './RequestLogDetail'
import { RequestContent } from './RequestContentComponent'
import { Loading } from '../common/Loading'

const StyledBody = styled(Layout.Content)`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const StyledListSider = styled(Layout.Sider)`
  background-color: #fff;
  height: 100%;
`

const StyledRequestList = styled(List)`
  border-right: 1px solid #e7e7e7;
`

const StyledRequestDetailContent = styled(Layout.Content)`
  background-color: #fff;
`

export const RequestListComponent = withRouter(
  ({
    requestsResponse = {},
    replayRequest,
    subscribeToNewRequestLogs,
    history,
    onLoadMore
  }) => {
    const [selectedRequestId, setSelectedRequestId] = useState(-1)
    const [loadedAll, setLoaddedAll] = useState(false)
    const { requests, hasNextPage } = requestsResponse
    useEffect(() => {
      subscribeToNewRequestLogs()
    }, [])
    useEffect(() => {
      if (!hasNextPage && !loadedAll) {
        message.info('Loaded all requests')
        setLoaddedAll(true)
      }
    })
    return (
      <Layout style={{ height: '100%' }}>
        <PageHeader
          title="Proxee"
          subTitle="Request list"
          onBack={() => history.push('/proxees')}
        />
        <StyledBody>
          <Layout>
            <StyledListSider width={380}>
              <SimpleBar style={{ height: '100%' }}>
                <InfiniteScroll
                  hasMore={hasNextPage}
                  initialLoad={false}
                  loadMore={onLoadMore}
                  useWindow={false}
                  loader={<Loading key={1} />}
                >
                  <StyledRequestList
                    itemLayout="horizontal"
                    dataSource={requests || []}
                    renderItem={request => (
                      <RequestContent
                        selected={selectedRequestId === request.id}
                        request={request}
                        onReplay={() => replayRequest(request.id)}
                        onSelected={() => setSelectedRequestId(request.id)}
                      />
                    )}
                  />
                </InfiniteScroll>
              </SimpleBar>
            </StyledListSider>
            <StyledRequestDetailContent>
              {selectedRequestId !== -1 && (
                <RequestLogDetail id={selectedRequestId} />
              )}
            </StyledRequestDetailContent>
          </Layout>
        </StyledBody>
      </Layout>
    )
  }
)
