import React from 'react'
import styled from 'styled-components'
import { Icon, List, message, Tooltip } from 'antd'
import url from 'url'

const ListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const HoverableListItem = styled(List.Item)`
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  background: #fff;
  transition: border-right 0.1s ease-in-out;
  border-right: ${props => (props.selected ? '3px solid #1a7e83' : 'none')};
  font-weight: ${props => (props.selected ? 600 : 400)};
  :hover {
    border-right: 3px solid #1a7e83;
    color: #1a7e83;
    cursor: pointer;
  }
`

const HiddenAction = styled(Icon)`
  font-size: 16px;
  font-weight: 200;
  margin: 0.3em;
  visibility: hidden;
  transition: font-weight 0.3s ease-in-out;
  ${HoverableListItem}:hover & {
    visibility: visible;
  }
  :hover {
    font-weight: 500;
  }
`

const StyledPathPart = styled.div`
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`

const StyledMiddlePart = styled.div`
  width: 50px;
`

const StyledLastPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`

const StyledResponseTime = styled.div`
  width: 50px;
`

export const RequestContent = ({ request, onSelected, onReplay, selected }) => {
  const parsedUrl = url.parse(request.path, true)
  return (
    <HoverableListItem selected={selected} onClick={onSelected}>
      <ListItemContent>
        <StyledPathPart>{`${request.method} ${
          parsedUrl.pathname
        }`}</StyledPathPart>
        <StyledMiddlePart>{request.statusCode}</StyledMiddlePart>
        <StyledLastPart>
          <Tooltip title="Copy">
            {request.curlQuery && (
              <HiddenAction
                type="copy"
                onClick={e => {
                  navigator.clipboard.writeText(request.curlQuery)
                  message.info('curl command copied!')
                  e.stopPropagation()
                }}
              />
            )}
          </Tooltip>
          <Tooltip title="Replay">
            <HiddenAction
              type="reload"
              onClick={e => {
                onReplay()
                e.stopPropagation()
              }}
            />
          </Tooltip>
          <StyledResponseTime>{`${request.responseTime}ms`}</StyledResponseTime>
        </StyledLastPart>
      </ListItemContent>
    </HoverableListItem>
  )
}
