import React from 'react'
import styled from 'styled-components'
import { Icon, Card } from 'antd'

const githubOauthUrl = new URL(
  `${process.env.REACT_APP_GITHUB_AUTH_BASE_URL}/authorize`
)
githubOauthUrl.searchParams.set(
  'client_id',
  process.env.REACT_APP_GITHUB_APP_ID
)
githubOauthUrl.searchParams.set('scope', 'read:user;user:email')
githubOauthUrl.searchParams.set(
  'redirect_uri',
  `${process.env.REACT_APP_BASE_URL}/auth/github`
)

const gitlabOauthUrl = new URL(process.env.REACT_APP_GITLAB_AUTH_BASE_URL)
gitlabOauthUrl.searchParams.set('response_type', 'token')
gitlabOauthUrl.searchParams.set(
  'client_id',
  process.env.REACT_APP_GITLAB_APP_ID
)
gitlabOauthUrl.searchParams.set('scope', 'read_user')
gitlabOauthUrl.searchParams.set(
  'redirect_uri',
  `${process.env.REACT_APP_BASE_URL}/auth/gitlab`
)

const SignInPageStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginButtonWrapperStyled = styled.div`
  padding: 0.3em;
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
  &:hover {
    color: #fff;
    background: ${props => props.background || '#000'};
    cursor: pointer;
  }
  margin: 0.5em;
  border-radius: 5px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
`

const LoginCardBodyStyled = styled.div`
  display: flex;
  flex-direction: row;
`

export const SignInComponent = () => {
  return (
    <SignInPageStyled>
      <Card title="Sign In">
        <LoginCardBodyStyled>
          <LoginButtonWrapperStyled
            onClick={() => (window.location = githubOauthUrl)}
          >
            <Icon type="github" style={{ fontSize: 50 }} />
            <span style={{ fontSize: 14 }}>Github</span>
          </LoginButtonWrapperStyled>
          <LoginButtonWrapperStyled
            background="#E5542C"
            onClick={() => (window.location = gitlabOauthUrl)}
          >
            <Icon type="gitlab" style={{ fontSize: 50 }} />
            <span style={{ fontSize: 14 }}>Gitlab</span>
          </LoginButtonWrapperStyled>
        </LoginCardBodyStyled>
      </Card>
    </SignInPageStyled>
  )
}
