import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RequestListContainer } from './RequestList/RequestListContainer'

export const Proxee = () => (
  <Switch>
    <Route path="/proxees/:proxeeID/" component={RequestListContainer} />
    <Route
      path="/proxees/:proxeeID/requests"
      component={RequestListContainer}
    />
  </Switch>
)
