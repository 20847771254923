import React from 'react'
import gql from 'graphql-tag'
import { Query, Subscription, ApolloConsumer, Mutation } from 'react-apollo'
import { Loading } from '../common/Loading'
import { RequestListComponent } from './RequestListComponent'

const LIST_REQUEST_QUERY = gql`
  query listRequest($proxeeID: Int!, $cursor: String, $first: Int, $last: Int) {
    requestLogs(
      args: {
        subDomainID: $proxeeID
        cursor: $cursor
        first: $first
        last: $last
      }
    ) {
      requests {
        id
        method
        path
        statusCode
        responseTime
        createdAt
        curlQuery
      }
      hasNextPage
      cursor
      __typename
    }
  }
`

const REPLAY_REQUEST_MUTATION = gql`
  mutation replayRequest($requestID: Int!) {
    replayRequest(id: $requestID) {
      id
      method
      path
      createdAt
    }
  }
`

const NEW_REQUEST_SUBSCRIPTION = gql`
  subscription newRequestSubscription($id: Int!) {
    requestAdded(id: $id) {
      id
      method
      path
      statusCode
      responseTime
      createdAt
      curlQuery
    }
  }
`

const RESPONSE_ARRIVED_SUBSCRIPTION = gql`
  subscription responseArrivedSubscription($id: Int!) {
    responseArrived(id: $id) {
      id
      body
      headers {
        field
        value
      }
      statusCode
      responseTime
      request {
        id
      }
    }
  }
`

export const RequestListContainer = ({
  match: {
    params: { proxeeID }
  }
}) => {
  return (
    <ApolloConsumer>
      {client => (
        <Subscription
          subscription={RESPONSE_ARRIVED_SUBSCRIPTION}
          variables={{ id: proxeeID }}
        >
          {({ data: responseArrivedSubData }) => {
            const { responseArrived } = responseArrivedSubData || {}
            if (responseArrived) {
              const { requestLogs } = client.cache.readQuery({
                query: LIST_REQUEST_QUERY,
                variables: { proxeeID, last: 20 }
              })
              const r = requestLogs.requests.filter(
                reqLog => reqLog.id === responseArrived.request.id
              )
              if (r.length > 0) {
                client.cache.writeQuery({
                  query: LIST_REQUEST_QUERY,
                  variables: { proxeeID, last: 20 },
                  data: {
                    requestLogs: {
                      ...requestLogs,
                      requests: requestLogs.requests.map(reqLog =>
                        reqLog.id !== responseArrived.request.id
                          ? reqLog
                          : {
                            ...reqLog,
                            statusCode: responseArrived.statusCode,
                            responseTime: responseArrived.responseTime
                          }
                      )
                    }
                  }
                })
              }
            }
            return (
              <Mutation mutation={REPLAY_REQUEST_MUTATION}>
                {replayRequestMutation => (
                  <Query
                    query={LIST_REQUEST_QUERY}
                    variables={{ proxeeID, last: 20 }}
                  >
                    {({ subscribeToMore, loading, error, data, fetchMore }) => {
                      if (loading) {
                        return <Loading />
                      }
                      if (error) {
                        return error.message
                      }
                      const { requestLogs } = data
                      const replayRequest = requestID =>
                        replayRequestMutation({
                          variables: { requestID }
                        })
                      return (
                        <RequestListComponent
                          requestsResponse={requestLogs}
                          replayRequest={replayRequest}
                          onLoadMore={() =>
                            fetchMore({
                              query: LIST_REQUEST_QUERY,
                              variables: {
                                proxeeID,
                                cursor: requestLogs.cursor,
                                last: 10
                              },
                              updateQuery: (prev, { fetchMoreResult }) => ({
                                requestLogs: {
                                  __typename: prev.requestLogs.__typename,
                                  cursor: fetchMoreResult.requestLogs.cursor,
                                  hasNextPage:
                                    fetchMoreResult.requestLogs.hasNextPage,
                                  requests: [
                                    ...prev.requestLogs.requests,
                                    ...fetchMoreResult.requestLogs.requests
                                  ]
                                }
                              })
                            })
                          }
                          subscribeToNewRequestLogs={() =>
                            subscribeToMore({
                              document: NEW_REQUEST_SUBSCRIPTION,
                              variables: { id: proxeeID },
                              updateQuery: (prev, { subscriptionData }) => {
                                if (!subscriptionData.data) return prev
                                const newRequest =
                                  subscriptionData.data.requestAdded
                                return Object.assign({}, prev, {
                                  requestLogs: {
                                    ...prev.requestLogs,
                                    requests: [
                                      newRequest,
                                      ...prev.requestLogs.requests
                                    ]
                                  }
                                })
                              }
                            })
                          }
                        />
                      )
                    }}
                  </Query>
                )}
              </Mutation>
            )
          }}
        </Subscription>
      )}
    </ApolloConsumer>
  )
}
