import React from 'react'
import { Drawer, Button } from 'antd'
import styled from 'styled-components'

const StyledBottomButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #e9e9e9;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 16px;
`

const StyledButton = styled(Button)`
  margin-left: 1em;
`

export const EntityDrawer = ({
  width,
  drawerTitle,
  onSubmit,
  open,
  onClose,
  children
}) => (
  <Drawer
    title={drawerTitle}
    placement="right"
    closable
    visible={open}
    onClose={onClose}
    width={width}
  >
    {children}
    <StyledBottomButtonGroup>
      <StyledButton onClick={onClose}>Cancel</StyledButton>
      <StyledButton type="primary" onClick={onSubmit}>
        Save
      </StyledButton>
    </StyledBottomButtonGroup>
  </Drawer>
)
