import React from 'react'
import { Tabs, List, Empty, Layout } from 'antd'
import styled from 'styled-components'
import url from 'url'
import SimpleBar from 'simplebar-react'
import { JSONTree } from '../common/JSONTree'

const TabPane = Tabs.TabPane

const RequestDetailWrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 2em 0;
  max-width: 800px;
  height: 100%;
`

const PartLayout = styled(Layout)`
  width: 100%;
`

const PartHeader = styled(Layout.Header)`
  padding-left: 0;
  background-color: #fff;
  display: flex;
  height: 48px;
  font-size: 24px;
  font-weight: 500;
  justify-content: space-between;
  > div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
  }
`

const PartContent = styled(Layout.Content)`
  background-color: #fff;
`

const FieldValueComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  min-width: 0;
  width: 550px;
`

const FieldComponent = styled.div`
  width: 200px;
  font-weight: bold;
`

const ValueComponent = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const renderFieldValue = ({ field, value }) => (
  <List.Item>
    <FieldValueComponent>
      <FieldComponent>{field}</FieldComponent>
      <ValueComponent>{value}</ValueComponent>
    </FieldValueComponent>
  </List.Item>
)

export const RequestLogDetailComponent = ({ request }) => {
  const { response = {} } = request
  const parsedUrl = url.parse(request.path, true)
  const queryTableData = Object.keys(parsedUrl.query).map((field, key) => ({
    key,
    field,
    value: parsedUrl.query[field]
  }))
  let bodyStr = ''
  try {
    bodyStr = <JSONTree data={JSON.parse(response.body)} />
  } catch {
    bodyStr = response.body
  }
  return (
    <RequestDetailWrapper>
      <SimpleBar>
        <PartLayout>
          <PartHeader>
            <div>{`${request.method} ${parsedUrl.pathname}`}</div>
          </PartHeader>
          <PartContent>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Query" key="1">
                <List
                  size="small"
                  bordered={false}
                  pagination={false}
                  dataSource={queryTableData}
                  renderItem={row => renderFieldValue(row)}
                />
              </TabPane>
              <TabPane tab="Headers" key="2">
                <List
                  dataSource={request.headers}
                  size="small"
                  bordered={false}
                  pagination={false}
                  renderItem={row => renderFieldValue(row)}
                />
              </TabPane>
              <TabPane tab="Payload" key="3">
                {request.body || <Empty />}
              </TabPane>
            </Tabs>
          </PartContent>
        </PartLayout>
        <PartLayout>
          <PartHeader>
            <div>{response.statusCode}</div>
          </PartHeader>
          <PartContent>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Headers" key="1">
                <List
                  size="small"
                  pagination={false}
                  dataSource={response.headers}
                  renderItem={row => renderFieldValue(row)}
                />
              </TabPane>
              <TabPane tab="Body" key="2">
                <div>{response.body ? <pre>{bodyStr}</pre> : <Empty />}</div>
              </TabPane>
            </Tabs>
          </PartContent>
        </PartLayout>
      </SimpleBar>
    </RequestDetailWrapper>
  )
}
