import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import 'simplebar/dist/simplebar.min.css'
import './index.css'
import App from './App'
import { ApolloProvider } from 'react-apollo'
import { message as Message } from 'antd'
import { ApolloClient, InMemoryCache } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { BrowserRouter as Router } from 'react-router-dom'
import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
import { loginWithLongTermTokenObservable } from './common/Auth'
import * as serviceWorker from './serviceWorker'

const graphqlLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || ''
    }
  }
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_URL}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('token')
      return { token }
    }
  }
})

const errorHandleLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(error => {
        const { message } = error
        return Message.error(message)
      })
    } else if (networkError) {
      if (networkError.statusCode === 401) {
        return loginWithLongTermTokenObservable(operation, forward)
      }
    }
  }
)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  errorHandleLink.concat(authLink.concat(graphqlLink))
)

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
