import React from 'react'
import { Tree } from 'antd'

const { TreeNode } = Tree

const loop = (key, value) => {
  const title = text => (
    <span>
      {key && <span style={{ color: '#D45FDD' }}>{key}: </span>}
      {text}
    </span>
  )
  switch (typeof value) {
  case 'number':
    return (
      <TreeNode
        key={key}
        title={title(<span style={{ color: '#987EFF' }}>{value}</span>)}
      />
    )
  case 'string':
    return (
      <TreeNode
        key={key}
        title={title(
          <span>
              "<span style={{ color: '#EF596F' }}>{value}</span>"
          </span>
        )}
      />
    )
  case 'object':
    if (Array.isArray(value)) {
      return (
        <TreeNode key={key} title={title('[,...]')}>
          {value.map((item, i) => loop(i.toString(), item))}
        </TreeNode>
      )
    } else {
      const valueStr = JSON.stringify(value, null, 0)
      return (
        <TreeNode
          key={key}
          title={title(
            valueStr.length > 50 ? `${valueStr.substr(0, 50)}...` : valueStr
          )}
        >
          {Object.keys(value).map(valueKey =>
            loop(valueKey, value[valueKey])
          )}
        </TreeNode>
      )
    }
  default:
    return <TreeNode key={key} title={title(value)} />
  }
}

export const JSONTree = ({ data }) => {
  return <Tree>{loop(null, data)}</Tree>
}
