import React, { useState } from 'react'
import { Icon, Button, Layout } from 'antd'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { ProxeeCard } from './ProxeeCard'
import { EntityDrawer } from '../common/EntityDrawer'
import { ProxeeDrawerContent } from './ProxeeDrawerContent'

const StyledProxeeList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
`

const StyledButton = styled(Button)`
  background-color: #207d82;
  color: #fff;
  margin-bottom: 1em;
`

const StyledProxeeListComponent = styled(Layout)`
  width: 100%;
`

const PageTitleHeader = styled(Layout.Header)`
  font-size: 24px;
  background-color: #fff;
  padding-left: 0;
`

const StyledListProxeeContent = styled(Layout.Content)`
  background-color: #fff;
`

const ProxeeList = ({ proxees, history, createProxee, updateProxee }) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [drawerType, setDrawerType] = useState('edit')
  const [selectedProxee, setSelectedProxee] = useState({})
  const handleUpdateField = field => e => {
    setSelectedProxee({
      ...selectedProxee,
      [field]: e.target.value
    })
  }
  return (
    <StyledProxeeListComponent>
      <EntityDrawer
        width={400}
        drawerTitle={
          drawerType === 'create' ? 'Create Proxee' : 'Update Proxee'
        }
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onSubmit={() => {
          if (drawerType === 'edit') {
            updateProxee(selectedProxee)
          } else {
            createProxee(selectedProxee)
          }
          setOpenDrawer(false)
        }}
      >
        <ProxeeDrawerContent
          onUpdatedField={handleUpdateField}
          proxee={selectedProxee}
          type={drawerType}
        />
      </EntityDrawer>
      <PageTitleHeader>Proxee API</PageTitleHeader>
      <StyledListProxeeContent>
        <StyledButton
          onClick={() => {
            setSelectedProxee({})
            setOpenDrawer(true)
            setDrawerType('create')
          }}
          size="large"
        >
          <Icon type="plus" />
          New proxee
        </StyledButton>
        <StyledProxeeList>
          {proxees &&
            proxees.map(proxee => (
              <ProxeeCard
                key={proxee.id}
                proxee={proxee}
                setSelectedProxee={setSelectedProxee}
                setOpenDrawer={setOpenDrawer}
                setDrawerType={setDrawerType}
                updateProxee={updateProxee}
                history={history}
              />
            ))}
        </StyledProxeeList>
      </StyledListProxeeContent>
    </StyledProxeeListComponent>
  )
}

export const ProxeeListComponent = withRouter(ProxeeList)
