import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Loading } from '../common/Loading'
import { RequestLogDetailComponent } from './RequestLogDetailComponent'

const REQUEST_LOG_DETAIL = gql`
  query requestLogDetail($id: Int!) {
    requestLog(id: $id) {
      id
      path
      method
      headers {
        field
        value
      }
      createdAt
      body
      response {
        id
        body
        headers {
          field
          value
        }
        statusCode
        responseTime
      }
    }
  }
`

export const RequestLogDetail = ({ id }) => (
  <Query query={REQUEST_LOG_DETAIL} variables={{ id }}>
    {({ data, loading }) => {
      if (loading) {
        return <Loading />
      }
      const { requestLog } = data
      return <RequestLogDetailComponent request={requestLog} />
    }}
  </Query>
)
