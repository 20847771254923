import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { message } from 'antd'
import { SignInComponent } from './SignInComponent'

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`

const handleSubmit = setLoggedIn => async values => {
  const response = await fetch(LOGIN_URL, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      email: values.email,
      password: values.password
    })
  })
  if (response.ok) {
    const tokenResponse = await response.json()
    localStorage.setItem('token', tokenResponse.token)
    localStorage.setItem('refreshToken', tokenResponse.refreshToken)
    setLoggedIn(true)
  } else {
    message.error(await response.text())
  }
}

const SignInContainer = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')
    if (token && refreshToken) {
      setLoggedIn(true)
    }
  })
  if (loggedIn) {
    return <Redirect to="/" />
  }
  return <SignInComponent onSubmit={handleSubmit(setLoggedIn)} />
}

export default SignInContainer
