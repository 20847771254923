import React from 'react'
import { Avatar, Icon, Dropdown, Menu, Layout } from 'antd'
import styled from 'styled-components'
import { logOut } from './common/Auth'

const StyledHeaderWrapper = styled(Layout.Header)`
  display: flex;
  position: fixed;
  z-index: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 8px #f0f1f2;
  background-color: #153f53;
  background-image: linear-gradient(141deg, #153f53 0%, #1a7e83 75%);
`

const StyledHeaderLogo = styled.div`
  padding-left: 100px;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #fff;
`

const NameHeaderMenuItem = styled.div`
  padding: 5px 12px;
  > i {
    margin-right: 8px;
  }
`

const StyledHeaderRest = styled.div`
  padding-right: 4em;
  display: flex;
  align-items: center;
  padding-right: 250px;
`

const Header = ({ user }) => {
  const menu = (
    <Menu style={{ width: 150 }}>
      <NameHeaderMenuItem key="1">
        <Icon type="user" />
        {user && user.name}
      </NameHeaderMenuItem>
      <Menu.Divider />
      <Menu.Item key="2" onClick={() => logOut()}>
        <Icon type="logout" />
        Logout
      </Menu.Item>
    </Menu>
  )
  return (
    <StyledHeaderWrapper>
      <StyledHeaderLogo>Proxee</StyledHeaderLogo>
      <StyledHeaderRest>
        <Dropdown overlay={menu} trigger={['click']}>
          {user && user.avatarURL ? (
            <Avatar src={user.avatarURL} />
          ) : (
            <Avatar icon="user" />
          )}
        </Dropdown>
      </StyledHeaderRest>
    </StyledHeaderWrapper>
  )
}

export default Header
