import { Observable } from 'apollo-link'

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`

let isFetchingToken = false
let tokenSubscribers = []
function subscribeTokenRefresh(cb) {
  tokenSubscribers.push(cb)
}
function onTokenRefreshed(err) {
  tokenSubscribers.map(cb => cb(err))
}

const retryRequest = (operation, observer, forward) => {
  const token = localStorage.getItem('token')
  const oldHeaders = operation.headers
  operation.setContext({
    headers: {
      ...oldHeaders,
      authorization: token ? `Bearer ${token}` : ''
    }
  })

  const subscriber = {
    next: observer.next.bind(observer),
    error: observer.error.bind(observer),
    complete: observer.complete.bind(observer)
  }

  return forward(operation).subscribe(subscriber)
}

export const logOut = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('github_scope')
  localStorage.removeItem('github_token')
  window.location.href = '/login'
}

const loginWithLongTermToken = async refreshToken => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    const bodyObj =
      refreshToken && refreshToken.length() > 0
        ? {
          type: 'refreshToken',
          refreshToken
        }
        : {
          type: 'githubToken',
          githubToken: localStorage.getItem('github_token')
        }
    console.log(bodyObj)
    const response = await fetch(LOGIN_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(bodyObj)
    })
    if (response.ok) {
      const tokenResponse = await response.json()
      localStorage.setItem('token', tokenResponse.token)
      if (bodyObj.type === 'refreshToken') {
        localStorage.setItem('refreshToken', tokenResponse.refreshToken)
      }
      return tokenResponse.token
    } else {
      throw new Error(response.errors[0].message)
    }
  } catch (e) {
    logOut()
  }
}

export const loginWithLongTermTokenObservable = (operation, forward) =>
  new Observable(async observer => {
    if (!isFetchingToken) {
      isFetchingToken = true
      try {
        await loginWithLongTermToken()
        isFetchingToken = false
        onTokenRefreshed(null)
        tokenSubscribers = []
        return retryRequest(operation, observer, forward)
      } catch (e) {
        onTokenRefreshed(new Error('Unable to refresh access token'))

        tokenSubscribers = []
        isFetchingToken = false

        logOut()
      }
    }
    const tokenSubscriber = new Promise((resolve, reject) => {
      subscribeTokenRefresh(errRefreshing => {
        if (errRefreshing) return reject(errRefreshing)

        return resolve(retryRequest(operation, observer, forward))
      })
    })
    return tokenSubscriber
  })
