import React from 'react'
import styled from 'styled-components'
import { Route, Redirect, Switch } from 'react-router-dom'
import Header from './Header'
import SignInContainer from './SignIn/SignInContainer'
import { ProxeeListContainer } from './ProxeeList/ProxeeListContainer'
import { Proxee } from './Proxee'
import { AuthContainer } from './SignIn/AuthContainer'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Layout } from 'antd'

export const UserProfileContext = React.createContext({})

const AppLayout = styled(Layout)`
  width: 100%;
  height: 100vh;
`

const AppContent = styled(Layout.Content)`
  padding-left: 150px;
  padding-top: 64px;
  background-color: #fff;
`

const QUERY_USER = gql`
  query getUser {
    user {
      id
      name
      email
      githubID
      avatarURL
      createdAt
    }
  }
`

const PrivateRoute = ({ component: Component, children, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('token') ? (
        Component ? (
          <Component {...props} />
        ) : (
          children
        )
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
)

const App = () => (
  <Switch>
    <Route path="/login" component={SignInContainer} />
    <Route path="/auth/:provider" component={AuthContainer} />
    <PrivateRoute path="/">
      <Query query={QUERY_USER}>
        {({ data: { user } }) => (
          <UserProfileContext.Provider value={user}>
            <AppLayout>
              <Header user={user} />
              <AppContent>
                <Switch>
                  <Route path="/" exact component={ProxeeListContainer} />
                  <Route
                    path="/proxees"
                    exact
                    component={ProxeeListContainer}
                  />
                  <Route path="/proxees/:proxeeID/" component={Proxee} />
                </Switch>
              </AppContent>
            </AppLayout>
          </UserProfileContext.Provider>
        )}
      </Query>
    </PrivateRoute>
  </Switch>
)

export default App
