import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  margin-bottom: 1em;
`

const StyledSpan = styled.span`
  font-weight: bold;
`

export const ProxeeDrawerContent = ({
  proxee = {},
  onUpdatedField,
  type = 'edit'
}) => {
  return (
    <div>
      <StyledSpan>Name</StyledSpan>
      <StyledInput
        placeholder="proxee name"
        value={proxee.name}
        onChange={onUpdatedField('name')}
      />
      <StyledSpan>Target URL</StyledSpan>
      <StyledInput
        placeholder="Target URL"
        value={proxee.targetUrl}
        onChange={onUpdatedField('targetUrl')}
      />
      <StyledSpan>Sub domain</StyledSpan>
      <StyledInput
        addonBefore="https://"
        addonAfter=".px.thangntt.com"
        disabled={type === 'edit'}
        value={proxee.subDomain}
        placeholder="sub-domain"
        onChange={onUpdatedField('subDomain')}
      />
    </div>
  )
}
